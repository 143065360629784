import type { FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { sendAnalyticsData, useDeposit } from '@mwl/core-lib';

import { paymentMobileSize } from '@/constants';
import { useMediaWidthDown } from '@/hooks';

import { PaymentLayout } from '../../../../components/PaymentLayout/PaymentLayout';
import { usePayloadDeposit } from '../../hooks/usePayloadDeposit/usePayloadDeposit';
import { DepositForm } from '../DepositForm/DepositForm';
import { DepositFormQrCode } from '../DepositFormQrCode/DepositFormQrCode';
import { DepositFormText } from '../DepositFormText/DepositFormText';

import type { DepositContentProps } from './DepositContent.types';

export const DepositContent: FC<DepositContentProps> = ({ analytics }) => {
  const isMobileScreen = useMediaWidthDown(paymentMobileSize);

  const canBeSelectFirstPayment = useRef(true);
  const firstMethodRef = useRef<HTMLButtonElement>(null);

  const { methods, isLoading } = usePayloadDeposit();
  const { payment, changePayment, content, changeContent } = useDeposit();

  const resetContent = useCallback(() => {
    changeContent({ form: 'create' });
  }, [changeContent]);

  useEffect(() => {
    if (methods?.length && !payment && !isMobileScreen && canBeSelectFirstPayment.current) {
      canBeSelectFirstPayment.current = false;
      const method = methods[0];
      changePayment(method);

      sendAnalyticsData(analytics?.payment?.open?.eventName, {
        ...(analytics?.payment?.open?.data || {}),
        cls: firstMethodRef.current?.className,
        img: method.card.image,
        payment_method: method.card.footer,
        payment_route_id: method.identity,
        payment_title: method.title,
        payment_form_type: method.type,
        position: 1,
        min_amount: method.min,
      });
    }
  }, [methods, changePayment, payment, isMobileScreen, analytics]);

  return (
    <PaymentLayout
      firstMethodRef={firstMethodRef}
      payment={payment}
      isLoading={isLoading}
      methods={methods}
      onChangePayment={changePayment}
      analytics={analytics?.payment}
    >
      {payment && <DepositForm analytics={analytics?.form} />}
      {content.form === 'qrCode' && <DepositFormQrCode onClose={resetContent} analytics={analytics?.qrCode} />}
      {content.form === 'text' && payment?.method && <DepositFormText {...payment} />}
    </PaymentLayout>
  );
};
