import { reducers } from '@mwl/core-lib';
import { combineReducers } from '@reduxjs/toolkit';

import { sportCouponReducer } from './sportCoupon/reducers';
import { uiReducer } from './ui/reducers';

export const rootReducer = combineReducers({
  ...reducers,
  ui: uiReducer,
  sportCoupon: sportCouponReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
