import type { ChangeEventHandler, FC } from 'react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import type { Analytics } from '@mwl/core-lib';
import {
  fetchLogin,
  loginSuccess,
  onlyNumbers,
  removeAllSpaces,
  sendAnalyticsData,
  useCountryList,
  useForm,
  useSubmitFormAuth,
} from '@mwl/core-lib';

import { FormFieldHidden } from '@/components/FormFieldHidden/FormFieldHidden';
import type {
  FormFieldPhoneOnChange,
  FormFieldPhoneOnSelect,
  FormFieldPhoneOption,
} from '@/components/FormFieldPhone/FormFieldPhone';
import { FormFieldPhone } from '@/components/FormFieldPhone/FormFieldPhone';
import { formatCountriesToPhoneOptions } from '@/components/FormFieldPhone/FormFieldPhone.utils';
import { FormMessage } from '@/components/FormMessage/FormMessage';
import { Loader } from '@/components/Loader/Loader';
import { useTypedDispatch } from '@/hooks';
import { formLoginObject } from '@/utils/markerTree';

import { ButtonSignIn } from '../ButtonSignIn/ButtonSignIn';
import { ForgotButton } from '../ForgotButton/ForgotButton';

import type { FormLoginPhoneProps, FormLoginPhoneValues } from './FormLoginPhone.types';
import { formSettings } from './FormLoginPhone.utils';

import styles from './FormLoginPhone.module.scss';

const BaseFormLoginPhone: FC<FormLoginPhoneProps> = ({ className, parentRef, onSuccess, analytics }) => {
  const { t } = useTranslation('common');

  const dispatch = useTypedDispatch();

  const { countries: countryList } = useCountryList();

  const options = useMemo(() => formatCountriesToPhoneOptions(countryList), [countryList]);

  const { changeField, formState, handleSubmit } = useForm<FormLoginPhoneValues>(formSettings);

  const {
    state: { error, isLoading },
    onSubmitConfirm,
  } = useSubmitFormAuth({
    requestHandler: fetchLogin,
    formState: formState.values,
    onSuccess: (data) => {
      if (data?.token) {
        sendAnalyticsData(analytics?.submit?.success?.eventName, analytics?.submit?.success?.data);
        dispatch(loginSuccess({ token: data.token }));
        onSuccess();
      }
    },
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value } = event.target;

      changeField(name as keyof FormLoginPhoneValues, removeAllSpaces(value));
    },
    [changeField],
  );

  const handlePhoneChange: FormFieldPhoneOnChange = useCallback((value) => changeField('phone', value), [changeField]);

  const handleSelect: FormFieldPhoneOnSelect<FormFieldPhoneOption> = useCallback(
    (option) => changeField('code', option),
    [changeField],
  );

  const checkFieldError = useCallback(
    (fieldName: keyof FormLoginPhoneValues) => {
      return Boolean(
        (formState.isTouched && formState.errors && formState.errors[fieldName]) || error?.type === '/bad-credentials',
      );
    },
    [formState, error],
  );

  const submitConfirm = useCallback(
    (value: FormLoginPhoneValues) => {
      onSubmitConfirm({
        credentialSubject: `${value.code.value}${onlyNumbers(value.phone)}`,
        password: value.password,
        rememberMe: true,
      });
    },
    [onSubmitConfirm],
  );

  const handleBlur = (fieldAnalytics?: Analytics) => () => {
    sendAnalyticsData(fieldAnalytics?.change?.eventName, fieldAnalytics?.change?.data);
  };

  useEffect(() => {
    const errors = [];

    if (checkFieldError('phone')) {
      errors.push('phone');
    }

    if (checkFieldError('password')) {
      errors.push('password');
    }

    if (errors.length) {
      sendAnalyticsData(analytics?.submit?.error?.eventName, {
        ...(analytics?.submit?.error?.data || {}),
        form_errors: errors,
      });
    }
  }, [analytics, checkFieldError]);

  useEffect(() => {
    if (options.length) {
      handleSelect(options[0]);
    }
  }, [options, handleSelect]);

  return (
    <form
      {...formLoginObject.phoneTab.nodeProps}
      className={cn(styles.root, className)}
      onSubmit={handleSubmit(submitConfirm)}
    >
      {error && (
        <FormMessage className={styles.message}>
          {Array.isArray(error.message) ? t(error.message) : error.message}
        </FormMessage>
      )}
      {isLoading && <Loader />}
      <FormFieldPhone
        {...formLoginObject.phoneTab.phoneInput.nodeProps}
        options={options}
        value={formState.values.phone}
        className={styles.field}
        selected={formState.values.code}
        parentRef={parentRef}
        onSelect={handleSelect}
        onChange={handlePhoneChange}
        onBlur={handleBlur(analytics?.phone)}
        isError={checkFieldError('phone')}
        classes={{ component: styles.input }}
        analytics={analytics?.countryCode}
        adaptive
      />
      <FormFieldHidden
        {...formLoginObject.phoneTab.passwordInput.nodeProps}
        placeholder={t('form.field.password', 'Password')}
        value={formState.values.password}
        onChange={handleChange}
        onBlur={handleBlur(analytics?.password)}
        name="password"
        className={styles.field}
        isError={checkFieldError('password')}
        classes={{ component: styles.input }}
        analytics={analytics?.password}
      />
      <ForgotButton analytics={analytics?.forgotLink} {...formLoginObject.phoneTab.resetLink.nodeProps} />
      <ButtonSignIn analytics={analytics?.signIn} {...formLoginObject.phoneTab.submitButton.nodeProps} />
    </form>
  );
};

const FormLoginPhone = memo(BaseFormLoginPhone);

export * from './FormLoginPhone.types';
export { FormLoginPhone };
