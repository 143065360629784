import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { paymentFieldOptionToSelectOption } from '@mwl/core-lib';
import type { SelectOption } from '@mwl/ui';

import { Select } from '@/components/Select/Select';

import type { PaymentSelectProps } from './PaymentSelect.types';

export const PaymentSelect: FC<PaymentSelectProps> = ({ parentRef, onChange, value, title, ...restProps }) => {
  const { t } = useTranslation('payment');

  const options = (restProps.options || []).map<SelectOption>((option) => paymentFieldOptionToSelectOption(option, t));

  return (
    <Select
      {...restProps}
      selected={value as SelectOption}
      options={options}
      onSelect={onChange}
      placeholder={title ? t(title) : ''}
      parentRef={parentRef}
      colorVariant="light"
    />
  );
};
