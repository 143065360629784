import type { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import {
  FingerprintProvider,
  GrowthBookProvider,
  initializeAxios,
  useAuth,
  useAuthReload,
  useDocumentVHeight,
  useInitializeAxios,
  useInWebView,
  useJivoChat,
  useLocale,
  usePartnerParams,
  useScrollbarWidth,
  useServerDeviceId,
  useSsoLogin,
  useWebsocketUser,
} from '@mwl/core-lib';
import { MwlProvider } from '@mwl/ui';

import '@/styles/base.scss';

import { AppsFlyerBanner } from '@/components/AppsFlyerBanner/AppsFlyerBanner';
import { ModalMessageApp } from '@/components/ModalMessageApp/ModalMessageApp';
import { PaymentWidget } from '@/components/PaymentWidget/PaymentWidget';
import { PopupAuth } from '@/components/PopupAuth/PopupAuth';
import { PopupWheel } from '@/components/PopupWheel/PopupWheel';
import { ReCaptcha } from '@/components/ReCaptcha/ReCaptcha';
import { base, fingerprintEndpoint, theme, widgetIds } from '@/constants';
import { RouterHistoryProvider } from '@/context';
import { useInitializeApp } from '@/hooks';
import { Favicon } from '@/Layouts/Favicon/Favicon';
import { Meta } from '@/Layouts/Meta/Meta';
import { Preloader } from '@/Layouts/Preloader/Preloader';
import { PopupPayment } from '@/modules/payment/components/PopupPayment/PopupPayment';
import { wrapperStore } from '@/store/store';

import nextI18NextConfig from '../../next-i18next.config';

initializeAxios();

const App = ({ Component, pageProps }: AppProps) => {
  useInitializeAxios();
  useInitializeApp();
  useAuth();
  useLocale();
  usePartnerParams();
  useJivoChat({ widgetIds });
  useInWebView();
  useSsoLogin();
  useAuthReload();
  useScrollbarWidth();
  useServerDeviceId();
  useDocumentVHeight();
  useWebsocketUser({ projectName: base.NAME_PROJECT });

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <Favicon />
      <Meta />
      <AppsFlyerBanner />
      <Preloader />
      <RouterHistoryProvider>
        <FingerprintProvider endpoint={fingerprintEndpoint} brandName="Banzai">
          <GrowthBookProvider>
            <MwlProvider theme={theme}>
              <PopupAuth />
              <PopupPayment />
              <ModalMessageApp />
              <PopupWheel />
              <Component {...pageProps} />
            </MwlProvider>
          </GrowthBookProvider>
        </FingerprintProvider>
      </RouterHistoryProvider>
      <ReCaptcha style={{ display: 'none' }} size="invisible" badge="inline" />
      <PaymentWidget />
    </>
  );
};

export default wrapperStore.withRedux(appWithTranslation(App, nextI18NextConfig));
