import { selectUserIsAuthenticated } from '@mwl/core-lib';

import { createAuthenticatedOnly } from '@/utils';

import type { NavMenuItem } from '@/types';

import { useTypedSelector } from './useTypedSelector';

/**
 * Возвращает массив маршрутов, доступных только авторизованным пользователям.
 * @param routes - Массив маршрутов
 * @returns Отфильтрованный массив маршрутов, доступных только авторизованным пользователям
 */
export const useAuthRoutes = (routes: Array<NavMenuItem>) => {
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  return routes.filter(createAuthenticatedOnly({ isAuth }));
};
