import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { format } from 'date-fns/format';
import { useTranslation } from 'next-i18next';
import { getEighteenYearsAgoDate, useAuthFormOptions, useRegistrationPhoneHandlers } from '@mwl/core-lib';
import { FlagIcon } from '@mwl/ui';

import { Button } from '@/components/Button/Button';
import { DatepickerDynamic } from '@/components/DatepickerDynamic/DatepickerDynamic';
import { FormField } from '@/components/FormField/FormField';
import { FormFieldHidden } from '@/components/FormFieldHidden/FormFieldHidden';
import { FormFieldPhone } from '@/components/FormFieldPhone/FormFieldPhone';
import { Select } from '@/components/Select/Select';
import { useRegister } from '@/context';

import { ButtonHaveAccount } from '../ButtonHaveAccount/ButtonHaveAccount';

import { useSecondStepRegistrationForm } from './SecondStepRegistration.hooks';
import type { SecondStepRegistrationProps, SecondStepRegistrationValues } from './SecondStepRegistration.types';

import styles from './SecondStepRegistration.module.scss';

const SecondStepRegistration: FC<SecondStepRegistrationProps> = ({
  isModal,
  bonusType,
  onChangeStep,
  setFormData,
  className,
  parentRef,
}) => {
  const { t } = useTranslation('common');
  const { updateCurrency } = useRegister();

  const { codes, currencies, countries, userCountry, defaultCountry } = useAuthFormOptions({ FlagIcon });

  const { changeField, formState, handleSubmit } = useSecondStepRegistrationForm({
    setFormData,
    bonusType,
    onChangeStep,
  });

  const { handleSelectCode, handleSelectCurrency, handlePhoneChange, handleChange, handleSelectCountry } =
    useRegistrationPhoneHandlers<SecondStepRegistrationValues>({
      countries,
      codes,
      changeField,
      updateCurrency,
    });

  const handleChangeDate = useCallback(
    (time: number) => {
      changeField('dateOfBirth', format(new Date(time), 'yyyy-MM-dd'));
    },
    [changeField],
  );

  const getBaseFieldProps = <T extends keyof SecondStepRegistrationValues>(name: T) => {
    return {
      name,
      onChange: handleChange,
      value: formState.values[name],
      isError: Boolean(formState.isTouched && formState.errors?.[name]),
    };
  };

  useEffect(() => {
    if (codes.length) {
      handleSelectCode(codes[0]);
    }
  }, [codes, handleSelectCode]);

  useEffect(() => {
    if (countries.length) {
      const countryValue = countries.find((country) => country.value === userCountry) ?? defaultCountry;
      handleSelectCountry(countryValue);
    }
  }, [countries, defaultCountry, handleSelectCountry, userCountry]);

  useEffect(() => {
    if (formState.values.country && currencies?.length) {
      const currencyOption = currencies.find((value) => value.value === formState.values.country.currency);
      handleSelectCurrency(currencyOption ?? currencies[0]);
    }
  }, [formState.values.country, currencies, handleSelectCurrency]);

  const handleClickBack = useCallback(() => onChangeStep('first'), [onChangeStep]);

  return (
    <form className={cn(styles.root, className)} onSubmit={handleSubmit}>
      <div className={cn({ [styles.row]: !isModal })}>
        <div className={cn(styles.row, { [styles.column]: !isModal })}>
          <FormField
            as="input"
            placeholder={t('form.field.firstName', 'First Name')}
            className={styles.column}
            {...getBaseFieldProps('firstName')}
          />
          <FormField
            as="input"
            placeholder={t('form.field.lastName', 'Last Name')}
            className={styles.column}
            {...getBaseFieldProps('lastName')}
          />
        </div>
        <div className={cn({ [styles.row]: isModal, [styles.column]: !isModal })}>
          <DatepickerDynamic
            onChange={handleChangeDate}
            selected={+new Date(formState.values.dateOfBirth).getTime()}
            maxDate={getEighteenYearsAgoDate() as unknown as Date}
            className={cn(styles.datePicker, {
              [styles.datePickerModal]: isModal,
              [styles.datePickerError]: Boolean(formState.isTouched && formState.errors?.dateOfBirth),
            })}
            popperPlacement="bottom-end"
            placeholder={t('form.field.date_of_birth', 'Date of birth')}
            shouldCloseOnSelect
            dateFormat="dd.MM.yyyy"
            disabledKeyboardNavigation
          />
          {isModal && (
            <div className={cn(styles.column, styles.columnWithError)}>
              <FormFieldPhone
                value={formState.values.phone}
                options={codes}
                selected={formState.values.code}
                parentRef={parentRef}
                onSelect={handleSelectCode}
                onChange={handlePhoneChange}
                isError={Boolean(formState.isTouched && formState.errors?.phone)}
              />
              {Boolean(formState.isTouched && formState.errors?.phone) && (
                <p className={styles.errorMessage}>{t(formState.errors?.phone)}</p>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={cn({ [styles.row]: !isModal })}>
        {!isModal && (
          <div className={cn(styles.column, styles.columnWithError)}>
            <FormFieldPhone
              value={formState.values.phone}
              options={codes}
              selected={formState.values.code}
              parentRef={parentRef}
              onSelect={handleSelectCode}
              onChange={handlePhoneChange}
              isError={Boolean(formState.isTouched && formState.errors?.phone)}
            />
            {Boolean(formState.isTouched && formState.errors?.phone) && (
              <p className={styles.errorMessage}>{t(formState.errors?.phone)}</p>
            )}
          </div>
        )}
        <div className={cn(styles.row, styles.rowWithError, { [styles.column]: !isModal })}>
          <div className={styles.column}>
            <FormField as="input" placeholder={t('form.field.email', 'E-mail')} {...getBaseFieldProps('email')} />
            {Boolean(formState.isTouched && formState.errors?.email) && (
              <p className={styles.errorMessage}>{t(formState.errors?.email)}</p>
            )}
          </div>
          <div className={styles.column}>
            <FormFieldHidden placeholder={t('form.field.password', 'Password')} {...getBaseFieldProps('password')} />
            {Boolean(formState.isTouched && formState.errors?.password) && (
              <p className={styles.errorMessage}>{t(formState.errors?.password)}</p>
            )}
          </div>
        </div>
      </div>
      <div className={cn(styles.row, styles.currencyRow)}>
        <Select
          selected={formState.values.country}
          options={countries}
          onSelect={handleSelectCountry}
          className={cn(styles.column, styles.select)}
          parentRef={parentRef}
        />
        <Select
          selected={formState.values.currency}
          options={currencies}
          onSelect={(option) => handleSelectCurrency(option)}
          className={cn(styles.column, styles.select)}
          parentRef={parentRef}
        />
      </div>
      <div className={styles.row}>
        <FormField
          as="input"
          placeholder={t('form.field.address', 'Address')}
          className={styles.column}
          {...getBaseFieldProps('address')}
        />
        <FormField
          as="input"
          placeholder={t('form.field.zipCode', 'Zip Code')}
          className={styles.column}
          {...getBaseFieldProps('zipCode')}
        />
      </div>
      <div className={cn(styles.row, styles.buttonContainer)}>
        {isModal ? (
          <ButtonHaveAccount className={cn(styles.column, styles.accountButton)} />
        ) : (
          <Button
            as="button"
            variant="outlined"
            size="md"
            fontSize="xl"
            fontWeight="bold"
            fullWidth
            className={styles.column}
            onClick={handleClickBack}
          >
            {t('auth.back', 'Back')}
          </Button>
        )}
        <Button
          as="button"
          variant="fulfilled"
          color="yellow"
          fontSize="xl"
          fontWeight="bold"
          size="md"
          fullWidth
          className={styles.column}
          type="submit"
        >
          {t('auth.proceed', 'Proceed')}
        </Button>
      </div>
    </form>
  );
};

export { SecondStepRegistration };
