import type { ChangeEvent, ChangeEventHandler, FC, FocusEvent, FocusEventHandler, Ref } from 'react';
import { forwardRef, memo, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import {
  addFieldNameToAnalytics,
  capitalizeFirstLetter,
  extendAnalyticsData,
  handleEventWithAnalytics,
} from '@mwl/core-lib';
import { FormField as FormFieldBase } from '@mwl/ui';

import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel';

import type { FormFieldProps, NumberFormatProps } from './FormField.types';
import { getInputClassName } from './FormField.utils';

import styles from './FormField.module.scss';

const NumberFormatAdapter: FC<NumberFormatProps> = forwardRef(
  ({ numberFormatPrefix, numberFormatSuffix, ...props }, ref) => {
    return <NumberFormat {...props} getInputRef={ref} prefix={numberFormatPrefix} suffix={numberFormatSuffix} />;
  },
);

const BaseFormField = forwardRef<HTMLInputElement | HTMLTextAreaElement, FormFieldProps>(
  (
    {
      className,
      prefix,
      postfix,
      isError,
      isSuccess,
      isConfirmed,
      colorVariant = 'default',
      label,
      errorText,
      size = 'md',
      analytics,
      onChange,
      onBlur,
      hasApprove,
      approveText,
      name,
      disabled,
      classes,
      labelOverflow = true,
      ...props
    },
    ref,
  ) => {
    const inputClassName = useMemo(
      () => getInputClassName({ as: props.as, isError, isSuccess, postfix, prefix, colorVariant, size }),
      [props.as, isError, isSuccess, postfix, prefix, colorVariant, size],
    );

    const labelText = useMemo(() => {
      if (hasApprove) {
        return approveText;
      }

      if (isError && errorText) {
        return errorText;
      }

      return label;
    }, [approveText, errorText, hasApprove, isError, label]);

    const labelElement = useMemo(() => {
      if (!label && !isError && !errorText) {
        return null;
      }

      return (
        <FormFieldLabel
          htmlFor={props.id}
          hasError={Boolean(isError && errorText)}
          hasValue={!!props.value}
          hasApprove={hasApprove}
          hasOverflow={labelOverflow}
          className={cn(
            styles.label,
            classes?.label,
            { [styles.confirmed]: isConfirmed },
            styles[`labelSize${capitalizeFirstLetter(size)}`],
          )}
        >
          {labelText}
        </FormFieldLabel>
      );
    }, [
      errorText,
      hasApprove,
      isConfirmed,
      isError,
      label,
      labelOverflow,
      labelText,
      props.id,
      props.value,
      size,
      classes,
    ]);

    const componentClassName = cn(inputClassName, classes?.component, analytics?.input?.data?.cls);
    const analyticsWithClassName = extendAnalyticsData(analytics, { cls: componentClassName });

    const handleOnChange = handleEventWithAnalytics<ChangeEvent<HTMLTextAreaElement | HTMLInputElement>>(
      onChange as ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
      analyticsWithClassName?.input?.eventName,
      addFieldNameToAnalytics(analyticsWithClassName?.input?.data, name),
    );

    const handleOnBlur = handleEventWithAnalytics<FocusEvent<HTMLTextAreaElement | HTMLInputElement>>(
      onBlur as FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
      analyticsWithClassName?.change?.eventName,
      analyticsWithClassName?.change?.data,
    );

    const commonProps = {
      name,
      onChange: handleOnChange,
      onBlur: handleOnBlur,
      labelElement,
      disabled,
      size,
      className: cn(styles.root, className, {
        [styles.error]: isError,
        [styles.success]: isSuccess,
      }),
      classes: {
        component: componentClassName,
        prefix: cn(styles.prefix, classes?.prefix),
        postfix: cn(styles.postfix, classes?.postfix),
      },
    };

    const inputProps = {
      ...commonProps,
      prefix,
      postfix,
    };

    if (props.as === 'textarea') {
      return (
        <FormFieldBase
          component="textarea"
          ref={ref as Ref<HTMLTextAreaElement>}
          {...props}
          {...commonProps}
          className={cn(commonProps.className, styles.textareaField)}
        />
      );
    }

    if (props.as === 'numberFormat') {
      return <FormFieldBase ref={ref} component={NumberFormatAdapter} {...props} {...inputProps} />;
    }

    return <FormFieldBase component="input" ref={ref as Ref<HTMLInputElement>} {...props} {...inputProps} />;
  },
);

const FormField = memo(BaseFormField);

export * from './FormField.types';
export { FormField };
