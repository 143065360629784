import type { FC } from 'react';
import { memo, useEffect, useRef } from 'react';
import type { PaymentItem } from '@mwl/core-lib';
import { extendAnalyticsData } from '@mwl/core-lib';

import { PaymentCard } from '@/components/PaymentCard/PaymentCard';
import { useMediaWidthDown } from '@/hooks';
import { popupsObject } from '@/utils';

import type { PaymentGridProps } from './PaymentGrid.types';

import styles from './PaymentGrid.module.scss';

const BasePaymentGrid: FC<PaymentGridProps> = ({
  firstMethodRef,
  list,
  payment,
  classes,
  className,
  onChangePayment,
  analytics,
}) => {
  const isMobileScreen = useMediaWidthDown('screenXs');

  const formContainerRef = useRef<HTMLDivElement>(null);

  const getAnalytics = (item: PaymentItem, index: number) => {
    return extendAnalyticsData(analytics, {
      img: item.card.image,
      payment_method: item.card.footer,
      payment_identity: item.identity,
      payment_title: item.title,
      payment_form_type: item.type,
      position: index + 1,
      min_amount: item.min,
    });
  };

  useEffect(() => {
    if (payment?.identity && isMobileScreen) {
      formContainerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [payment?.identity, isMobileScreen]);

  return (
    <div className={cn(styles.root, className)}>
      {list.map((item, index) => (
        <PaymentCard
          ref={index === 0 ? firstMethodRef : undefined}
          {...item.card}
          {...popupsObject.deposit.payment.cards(item.identity).nodeProps}
          classes={classes?.item}
          identity={item.identity}
          onClick={() => onChangePayment(item, index)}
          active={item.identity === payment?.identity}
          key={item.identity}
          analytics={getAnalytics(item, index)}
        />
      ))}
    </div>
  );
};

export * from './PaymentGrid.types';
export const PaymentGrid = memo(BasePaymentGrid);
