import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useCountryList } from '@mwl/core-lib';

import type { FormFieldPhoneOption } from '@/components/FormFieldPhone/FormFieldPhone';
import { FormFieldPhone } from '@/components/FormFieldPhone/FormFieldPhone';

import type { FormFieldPhoneProps } from './PaymentFieldPhone.types';

export const PaymentFieldPhone: FC<FormFieldPhoneProps> = ({
  availableCountryCodes,
  initValue,
  onChange,
  parentRef,
}): JSX.Element => {
  const { allCountries: countries } = useCountryList();

  const selected = useMemo(() => {
    const selectedCountry = countries.find((option) => option.iso_alpha3_code === initValue.countryCode);

    return selectedCountry
      ? { value: selectedCountry.phone_prefix, format: selectedCountry.phone_mask, label: selectedCountry.phone_prefix }
      : { value: countries[0].phone_prefix, format: countries[0].phone_mask, label: countries[0].phone_prefix };
  }, [countries, initValue]);

  const [code, setCode] = useState<FormFieldPhoneOption>(selected);
  const [phone, setPhone] = useState(initValue.number);

  const handleSelect = (option: FormFieldPhoneOption) => setCode(option);

  const handleChange = (value: string) => {
    setPhone(value);
  };

  const options = useMemo(() => {
    return countries.reduce<Array<FormFieldPhoneOption>>((acc, country) => {
      const { phone_prefix, iso_alpha3_code, phone_mask } = country;

      if (!availableCountryCodes?.length || availableCountryCodes?.includes(iso_alpha3_code)) {
        acc.push({
          label: phone_prefix,
          format: phone_mask,
          value: phone_prefix,
        });
      }

      return acc;
    }, []);
  }, [availableCountryCodes, countries]);

  useEffect(() => {
    onChange(`${code.value}${phone.replaceAll(' ', '')}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, code]);

  return (
    <FormFieldPhone
      options={options}
      selected={code || selected}
      onSelect={handleSelect}
      value={phone}
      onChange={handleChange}
      parentRef={parentRef}
      width="full"
      colorVariant="light"
    />
  );
};
