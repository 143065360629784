import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { CouponPaymentSourceItemV2, CouponPaymentSourceSB } from '@mwl/core-lib';
import {
  selectCouponPaymentAccountId,
  selectCouponPaymentSourcesV2,
  selectCouponPaymentSourceV2,
  selectUserCurrency,
  setPaymentSourceV2,
} from '@mwl/core-lib';

import { useTypedDispatch } from '../useTypedDispatch';
import { useTypedSelector } from '../useTypedSelector';

import type {
  AccountSelectDefaultOptions,
  AccountSelectOptionsItem,
  UseAccountSelectItemsParams,
} from './useAccountSelectItems.types';

const defaultOptionValues: AccountSelectDefaultOptions = {
  BALANCE: {
    text: ['coupon.select.real_balance', 'Your balance'],
    isShowPostfixIcon: true,
    disabled: false,
  },
  FREEBET: {
    text: ['coupon.select.freebet_balance', 'Freebet'],
    isShowPostfixIcon: true,
    disabled: false,
  },
  BONUS: {
    text: ['coupon.select.bonus_balance', 'Bonus account'],
    isShowPostfixIcon: true,
    disabled: false,
  },
};

/**
 * @description Хук для вычисления способов оплаты, доступных пользователю
 *
 * @param isRunAnimation - флаг для запуска анимации
 */
export const useAccountSelectItems = ({ isRunAnimation }: UseAccountSelectItemsParams = {}) => {
  const dispatch = useTypedDispatch();

  const animationTimer = useRef<NodeJS.Timeout>();
  const isAnimationShowed = useRef(false);

  const [isAnimate, setIsAnimate] = useState(false);

  const userPaymentSources: Array<CouponPaymentSourceItemV2> = useTypedSelector(selectCouponPaymentSourcesV2);
  const activePaymentSource = useTypedSelector(selectCouponPaymentSourceV2);
  const activePaymentSourceId = useTypedSelector(selectCouponPaymentAccountId);
  const currency = useTypedSelector(selectUserCurrency);

  const setActiveItem = useCallback(
    (activeItem: CouponPaymentSourceSB) => {
      dispatch(setPaymentSourceV2(activeItem));
    },
    [dispatch],
  );

  const optionItems: Array<AccountSelectOptionsItem> = userPaymentSources.map((source) => {
    const isActive = source.type === activePaymentSource && source.id === activePaymentSourceId;
    return { ...source, ...defaultOptionValues[source.type], active: isActive };
  });

  const firstFreebetAndBonusAccount: Array<AccountSelectOptionsItem> = useMemo(() => {
    const freebetAccount = userPaymentSources.find((source) => source.type === 'FREEBET' && source.amount > 0);
    const bonusAccount = userPaymentSources.find((source) => source.type === 'BONUS' && source.amount > 0);

    const result: Array<AccountSelectOptionsItem> = [];

    [freebetAccount, bonusAccount].forEach((account) => {
      if (!account) {
        return;
      }

      const resultItem: AccountSelectOptionsItem = { ...account, ...defaultOptionValues[account.type], active: false };

      result.push(resultItem);
    });

    return result;
  }, [userPaymentSources]);

  const activeItem = useMemo(() => optionItems.find((item) => item.active), [optionItems]);
  const hasFreebet = optionItems.some(({ type }) => type === 'FREEBET');
  const hasBonus = optionItems.some(({ type }) => type === 'BONUS');
  const hasFreebetOrBonus = hasFreebet || hasBonus;

  useEffect(() => {
    if (!activeItem && !!optionItems?.[0]) {
      setActiveItem(optionItems[0]);
    }
  }, [activeItem, optionItems, setActiveItem]);

  useEffect(() => {
    if (
      currency &&
      isRunAnimation &&
      hasFreebetOrBonus &&
      !isAnimationShowed.current &&
      !animationTimer.current &&
      activeItem?.type === 'BALANCE'
    ) {
      const animationDuration = 4_000;
      setIsAnimate(true);

      animationTimer.current = setTimeout(() => {
        setIsAnimate(false);
        isAnimationShowed.current = true;
      }, animationDuration);
    }

    return () => {
      if (animationTimer.current) {
        clearTimeout(animationTimer.current);
        setIsAnimate(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, hasFreebetOrBonus]);

  return {
    activeItem,
    isAnimate,
    optionItems,
    setActiveItem,
    firstFreebetAndBonusAccount,
    hasFreebetOrBonus,
    hasFreebet,
    hasBonus,
  };
};
