import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { type LinkUrl, getLinkIsActive, inlineQueries } from '@mwl/core-lib';

/**
 * Возвращает значение, указывающее, активен ли текущий пункт меню.
 * @param href - URL-адрес ссылки, для которой нужно определить активность.
 * @returns Значение, указывающее, активен ли текущий пункт меню.
 */
export const useIsMenuItemActive = (href: LinkUrl) => {
  const { pathname, query } = useRouter();

  return useMemo(() => {
    const inlinedPathname = inlineQueries(pathname, query);
    return getLinkIsActive(inlinedPathname, href);
  }, [href, pathname, query]);
};
