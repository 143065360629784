import type { CompetitorSB, CompetitorTypeSB } from '@mwl/core-lib';

/**
 * Возвращает объект с данными по компетиторам из массива компетиторов.
 */
export const getCompetitors = (
  competitors: Array<CompetitorSB>,
): Record<CompetitorTypeSB, CompetitorSB | undefined> => {
  return {
    HOME: competitors.find((item) => item.type === 'HOME'),
    AWAY: competitors.find((item) => item.type === 'AWAY'),
  };
};
