import { createReducer } from '@reduxjs/toolkit';

import { setCouponTab, setIsCouponSumChanged, setShowMobileCoupon, setShowPreviewCoupon } from './actions';
import type { SportCouponState } from './types';

const initialState: SportCouponState = {
  showMobileCoupon: false,
  showCouponPreview: false,
  isCouponSumChanged: false,
  couponTab: { active: 'coupon', disabled: null },
};

export const sportCouponReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setShowMobileCoupon, (state, action) => ({
      ...state,
      showMobileCoupon: action.payload,
    }))
    .addCase(setShowPreviewCoupon, (state, action) => ({
      ...state,
      showCouponPreview: action.payload,
    }))
    .addCase(setCouponTab, (state, action) => ({
      ...state,
      couponTab: action.payload,
    }))
    .addCase(setIsCouponSumChanged, (state, action) => ({
      ...state,
      isCouponSumChanged: action.payload,
    }));
});
