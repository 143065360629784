import type { FC } from 'react';

import LoaderIcon from '@public/assets/common/icons/loader.svg';
import LoaderSportIcon from '@public/assets/common/icons/loaderSport.svg';

import type { LoaderProps } from './Loader.types';

import styles from './Loader.module.scss';

const Loader: FC<LoaderProps> = ({ className, variant = 'default' }) => {
  return (
    <div className={cn(styles.root, className)}>
      {variant === 'sport' ? <LoaderSportIcon className={styles.svgSport} /> : <LoaderIcon className={styles.svg} />}
    </div>
  );
};

export * from './Loader.types';
export { Loader };
