import AllIcon from '@public/assets/casino/icons/all.svg';
import BaccaratIcon from '@public/assets/casino/icons/baccarat.svg';
import CardsIcon from '@public/assets/casino/icons/cards.svg';
import GameShowsIcon from '@public/assets/casino/icons/gameShows.svg';
import PokerIcon from '@public/assets/casino/icons/poker.svg';
import RouletteIcon from '@public/assets/casino/icons/roulette.svg';
import TvGamesIcon from '@public/assets/casino/icons/tvGames.svg';

import type { NavMenuItem } from '@/types';

import { routes } from '../routes';

export const liveCasinoNavMenu: Array<NavMenuItem> = [
  {
    key: 'header.navigation.casino.roulette',
    slug: 'roulette',
    text: 'Roulettes',
    href: { pathname: routes.liveCasino.category, query: { category: 'roulette' } },
    Icon: RouletteIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_rouletteBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.live_casino.baccarat',
    slug: 'baccarat',
    text: 'Baccarat',
    href: { pathname: routes.liveCasino.category, query: { category: 'baccarat' } },
    Icon: BaccaratIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_baccaratBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.live_casino.blackjack',
    slug: 'blackjack',
    text: 'Black jack',
    href: { pathname: routes.liveCasino.category, query: { category: 'blackjack' } },
    Icon: CardsIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_blackjackBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.live_casino.game_shows',
    slug: 'game-shows',
    text: 'Game shows',
    href: { pathname: routes.liveCasino.category, query: { category: 'game-shows' } },
    Icon: GameShowsIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_gameShowsBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.live_casino.poker',
    slug: 'poker',
    text: 'Poker',
    href: { pathname: routes.liveCasino.category, query: { category: 'poker' } },
    Icon: PokerIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_pokerBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.live_casino.tvgames',
    slug: 'tvgames',
    text: 'TV games',
    href: { pathname: routes.liveCasino.category, query: { category: 'tvgames' } },
    Icon: TvGamesIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_tvGamesBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.live_casino.all',
    text: 'All games',
    slug: 'all',
    href: { pathname: routes.liveCasino.category, query: { category: 'all' } },
    Icon: AllIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_allGamesBtn_click',
      },
    },
  },
];
