import type { FC } from 'react';
import { DepositProvider, selectUserProfileBonusType, selectUserRefillCount } from '@mwl/core-lib';

import { useTypedSelector } from '@/hooks';

import { DepositContent } from './components/DepositContent/DepositContent';
import { DepositModals } from './components/DepositModals/DepositModals';
import type { DepositProps } from './Deposit.types';

export const Deposit: FC<DepositProps> = ({ analytics }) => {
  const userRefillCount = useTypedSelector(selectUserRefillCount);
  const userBonusType = useTypedSelector(selectUserProfileBonusType);

  const isHideBonuses = userRefillCount === 0 && (!userBonusType || userBonusType === 'REFUSAL');

  return (
    <DepositProvider isHideBonuses={isHideBonuses}>
      <DepositContent analytics={analytics?.content} />
      <DepositModals analytics={analytics?.modals} />
    </DepositProvider>
  );
};
