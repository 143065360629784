import { useRouter } from 'next/router';

import { routes } from '@/constants';

/**
 * Хук возвращает true если текущая страница - страница sport history
 */
export const useIsSportHistoryPage = (): boolean => {
  const { pathname } = useRouter();

  return pathname.startsWith(routes.sport.home) && pathname === routes.sport.history;
};
