import type { FC } from 'react';
import { memo } from 'react';
import { getTestProps, handleEventWithAnalytics } from '@mwl/core-lib';
import { SwitchBase } from '@mwl/ui';

import type { SwitchProps } from './Switch.types';

import styles from './Switch.module.scss';

const BaseSwitch: FC<SwitchProps> = ({ active, disabled, classes, onChange, analytics, ...props }) => {
  const analyticsEvent = active ? analytics?.off : analytics?.on;

  const switchClassName = cn(styles.root, classes?.root, { [styles.disabled]: disabled });

  const handleChange = (isChecked: boolean) => {
    if (disabled) {
      return;
    }

    const analyticsData = {
      ...(analyticsEvent?.data || {}),
      cls: switchClassName,
    };

    const eventHandler = handleEventWithAnalytics(onChange, analyticsEvent?.eventName, analyticsData);

    eventHandler(isChecked);
  };

  return (
    <SwitchBase
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      wrapperProps={getTestProps(props) as any} // TODO(d.arutyunyan): fix later in @mwl/core-lib
      className={switchClassName}
      classes={{ track: styles.track, checked: styles.checked, thumb: cn(styles.thumb, classes?.control) }}
      onChange={handleChange}
      checked={active}
      radius="lg"
      size="md"
    />
  );
};

const Switch = memo(BaseSwitch);

export * from './Switch.types';
export { Switch };
