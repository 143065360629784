import { capitalizeFirstLetter } from '@mwl/core-lib';

import type { FormFieldProps } from './FormField.types';

import styles from './FormField.module.scss';

type GetInputClassNameParams = Required<Pick<FormFieldProps, 'as'>> & Omit<FormFieldProps, 'className'>;

export const getInputClassName = ({
  isError,
  isSuccess,
  as,
  prefix,
  postfix,
  colorVariant,
  size = 'md',
}: GetInputClassNameParams) => {
  const isInput = as !== 'textarea';

  return cn({
    [styles.error]: isInput && isError,
    [styles.success]: isInput && isSuccess,
    [styles.textarea]: !isInput,
    [styles.input]: isInput,
    [styles.withPrefix]: isInput && prefix,
    [styles.withPostfix]: isInput && postfix,
    [styles.light]: colorVariant === 'light',
    [styles[`size${capitalizeFirstLetter(size)}`]]: !!size,
  });
};
