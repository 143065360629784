import DepositIcon from '@public/assets/mobileMenu/icons/deposit.svg';
import LiveCasinoIcon from '@public/assets/mobileMenu/icons/liveCasino.svg';
import MainIcon from '@public/assets/mobileMenu/icons/main.svg';
import PromoIcon from '@public/assets/mobileMenu/icons/promo.svg';
import SignInIcon from '@public/assets/mobileMenu/icons/signIn.svg';
import SportIcon from '@public/assets/mobileMenu/icons/sport.svg';
import CasinoIcon from '@public/assets/mobileMenu/icons/сasino.svg';

import type { NavMenuItem } from '@/types';

import { routes } from '../routes';

const depositMenuItems: Array<NavMenuItem> = [
  {
    key: 'header.navigation.deposit',
    text: 'deposit',
    render: ['auth'],
    href: { query: 'deposit=1' },
    Icon: DepositIcon,
  },
  {
    key: 'header.navigation.sign_in',
    text: 'sign in',
    href: { query: 'login=1' },
    render: ['not_auth'],
    Icon: SignInIcon,
  },
];

const mainMenuItem: NavMenuItem = {
  key: 'header.navigation.main',
  text: 'main',
  href: routes.home,
  Icon: MainIcon,
};

const casinoMenuItem: NavMenuItem = {
  key: 'header.navigation.casino_home',
  text: 'casino',
  href: routes.casino.home,
  Icon: CasinoIcon,
};

const liveCasinoMenuItem: NavMenuItem = {
  key: 'header.navigation.casino.live',
  text: 'live casino',
  href: routes.liveCasino.home,
  Icon: LiveCasinoIcon,
};

const promoMenuItem: NavMenuItem = {
  key: 'header.navigation.promo.home',
  text: 'promo',
  href: routes.promo.home,
  Icon: PromoIcon,
};

const sportMenuItem: NavMenuItem = {
  key: 'header.navigation.sport_home',
  text: 'sport',
  href: routes.sport.home,
  Icon: SportIcon,
};

export const mobileNavItems: Array<NavMenuItem> = [
  mainMenuItem,
  casinoMenuItem,
  liveCasinoMenuItem,
  promoMenuItem,
  ...depositMenuItems,
];

export const mobileNavItemsWithSport: Array<NavMenuItem> = [
  casinoMenuItem,
  liveCasinoMenuItem,
  promoMenuItem,
  sportMenuItem,
  ...depositMenuItems,
];
