import type { ChangeEvent, MouseEvent } from 'react';
import { memo, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'next-i18next';
import { getDataCyProps, handleEventWithAnalytics } from '@mwl/core-lib';
import type { SelectOption } from '@mwl/ui';
import { OptionList, useSelectState } from '@mwl/ui';

import type { DropdownClasses } from '../Dropdown/Dropdown';
import { Dropdown } from '../Dropdown/Dropdown';

import { SelectHeader } from './components/SelectHeader/SelectHeader';
import { NativeSelect } from './NativeSelect/NativeSelect';
import type { SelectProps } from './Select.types';

import styles from './Select.module.scss';

const CustomSelect = <T extends SelectOption>({
  className,
  placeholder,
  selected,
  options: initOptions,
  numberFormat,
  classes,
  parentRef,
  filter,
  onSelect: onSelectProp,
  disabled,
  label,
  size,
  headerClasses,
  closeOnClickOutside,
  ...props
}: SelectProps<T>): JSX.Element => {
  const { t } = useTranslation('common');

  const { isOpen, onToggle, onChangeSearch, onSelect, onOpenChange, search, options } = useSelectState({
    selected,
    searchable: true,
    onSelect: onSelectProp,
    filter,
    initOptions,
  });

  const { optionList: optionListClassName, ...otherClasses } = classes ?? {};

  const header = (
    <SelectHeader
      {...props}
      className={otherClasses?.header}
      classes={headerClasses}
      isOpen={isOpen}
      prefix={selected?.prefix}
      search={search}
      onChange={onChangeSearch}
      onToggle={onToggle}
      numberFormat={numberFormat}
      placeholder={placeholder}
      disabled={disabled}
      label={label}
      size={size}
      {...getDataCyProps(props)}
    />
  );

  const dropdownClasses: DropdownClasses = useMemo(() => otherClasses || { content: styles.content }, [otherClasses]);

  return (
    <Dropdown
      customHeader={header}
      active={isOpen}
      onToggle={onOpenChange}
      classes={dropdownClasses}
      className={cn(styles.root, className)}
      parentRef={parentRef}
      disabled={disabled}
      width="target"
      mainAxis={false}
      closeOnClickOutside={closeOnClickOutside}
    >
      <OptionList
        className={optionListClassName}
        classes={{ empty: styles.empty, option: styles.option, optionPrefix: styles.prefix }}
        selected={selected}
        options={options}
        onSelect={onSelect}
        emptyMessage={t('select.empty', 'No option')}
      />
    </Dropdown>
  );
};

const BaseSelect = <T extends SelectOption>({
  onSelect,
  analytics,
  className,
  adaptive = true,
  colorVariant = 'default',
  headerClasses,
  ...props
}: SelectProps<T>): JSX.Element => {
  const statClassName: string | undefined = analytics?.select?.data?.cls;

  const headerClassesWithVariant = {
    input: cn(headerClasses?.input, styles[`${colorVariant}InputVariant`]),
    button: headerClasses?.button,
  };

  const handleOnSelect = (option: T, event?: MouseEvent<HTMLButtonElement> | ChangeEvent<HTMLSelectElement>) => {
    handleEventWithAnalytics(onSelect, analytics?.select?.eventName, {
      ...(analytics?.select?.data || {}),
      cls: event?.currentTarget?.className,
      text: option.label,
    })(option);
  };

  if (adaptive && isMobile) {
    return (
      <NativeSelect
        onSelect={handleOnSelect}
        className={cn(className, statClassName)}
        headerClasses={headerClassesWithVariant}
        {...props}
      />
    );
  }

  return (
    <CustomSelect
      onSelect={handleOnSelect}
      className={cn(className, statClassName)}
      colorVariant={colorVariant}
      headerClasses={headerClassesWithVariant}
      {...props}
    />
  );
};

export * from './Select.types';
export const Select = memo(BaseSelect) as typeof BaseSelect;
