import { useMediaQuery } from '@mwl/core-lib';

import variables from '@/styles/helpers/export.module.scss';

export type MediaWidthSize =
  | 'screen360'
  | 'screenXxs'
  | 'screenXs'
  | 'screen850'
  | 'screenSm'
  | 'screenMd'
  | 'screenLg'
  | 'screenXl'
  | 'screenXxl'
  | 'screen1100'
  | 'screen1032'
  | 'screen1100'
  | 'screen1274'
  | 'screen1284';

/**
 * Возвращает результат медиа-запроса для ширины экрана, меньшей указанного размера.
 * @param size - Размер, для которого выполняется медиа-запрос.
 * @returns Результат медиа-запроса для ширины экрана.
 */
export const useMediaWidthDown = (size: MediaWidthSize) => {
  return useMediaQuery(`(max-width: calc(${variables[size]} - 1px))`);
};
