import type { ChangeEventHandler } from 'react';
import { isMobileSafari } from 'react-device-detect';
import { capitalizeFirstLetter } from '@mwl/core-lib';

import ArrowIcon from '@public/assets/common/icons/arrow.svg';

import type { NativeSelectProps, SelectOption } from './NativeSelect.types';
import { findValue, optionToValue, valueFromOption } from './NativeSelect.utils';

import styles from './NativeSelect.module.scss';

const NativeSelect = <T extends SelectOption>({
  options,
  selected,
  className,
  onSelect,
  label,
  placeholder,
  disabled,
  headerClasses,
  size = 'md',
}: NativeSelectProps<T>): JSX.Element => {
  const handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = valueFromOption<T>(event.target.value);

    const nextOption = findValue({ options, value });

    if (!nextOption) {
      return;
    }

    onSelect(nextOption, event);
  };

  const value = selected?.value ? optionToValue(selected) : 'none';
  const isEmptyValue = value === 'none';

  const displayValue = (
    <>
      {selected?.prefix}&nbsp;<span className={cn({ [styles.selected]: selected?.prefix })}>{selected?.label}</span>
    </>
  );

  return (
    <div
      className={cn(styles.root, headerClasses?.input, className, {
        [styles.disabled]: disabled,
        [styles.safariMobile]: isMobileSafari,
        [styles[`size${capitalizeFirstLetter(size)}`]]: !!size,
      })}
    >
      {label && <label className={styles.label}>{label}</label>}
      <p className={cn({ [styles.current]: !isEmptyValue, [styles.placeholder]: isEmptyValue })}>
        {!isEmptyValue ? displayValue : placeholder}
      </p>
      <ArrowIcon className={cn(styles.icon, { [styles.iconDisabled]: disabled })} />
      <select onChange={handleChange} defaultValue={value} className={styles.select} disabled={disabled}>
        <option value="none" disabled selected={isEmptyValue}>
          {placeholder}
        </option>
        {options.map((option, index) => {
          const isSelected = value ? valueFromOption(value)?.value === option.value : undefined;
          return (
            <option key={`${option.value}_${index}`} value={optionToValue({ ...option, index })} selected={isSelected}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export { NativeSelect };
