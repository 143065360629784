import type { NavMenuItem } from '@/types';

import { AVIATOR_GAME_ID } from '../aviator';
import { routes } from '../routes';

export const aviatorNavMenuItem: NavMenuItem = {
  key: 'header.navigation.aviator',
  text: 'aviator',
  href: {
    pathname: routes.casino.game,
    query: { gameId: AVIATOR_GAME_ID },
  },
  isAviator: true,
};

export const buyBonusNavMenuItem: NavMenuItem = {
  key: 'header.navigation.buy_bonus',
  text: 'Buy bonus',
  href: {
    pathname: routes.casino.category,
    query: { category: 'bonus_buy' },
  },
  isBuyBonus: true,
};

const mainMenuItem: NavMenuItem = {
  key: 'header.navigation.main',
  text: 'Main',
  href: routes.home,
  exactMatch: true,
};

const casinoMenuItem: NavMenuItem = {
  key: 'header.navigation.casino_home',
  text: 'casino',
  href: routes.casino.home,
};

const liveCasinoMenuItem: NavMenuItem = {
  key: 'header.navigation.live_casino.home',
  text: 'live casino',
  href: routes.liveCasino.home,
};

const tournamentsMenuItem: NavMenuItem = {
  key: 'header.navigation.tournaments',
  text: 'tournaments',
  href: routes.tournaments.home,
};

// const pokerMenuItem = {
//   key: 'header.navigation.poker',
//   text: 'poker',
//   href: routes.poker.home,
// };

const sportMenuItem: NavMenuItem = {
  key: 'header.navigation.sport.home',
  href: routes.sport.home,
  text: 'Sport',
  strictlyHighlightedOnUrl: '/sport?st=PREGAME',
};

const sportLiveMenuItem: NavMenuItem = {
  key: 'header.navigation.sport.live',
  href: {
    pathname: routes.sport.home,
    query: { st: 'LIVE' },
  },
  text: 'Live',
};

export const desktopNavMenu: Array<NavMenuItem> = [
  mainMenuItem,
  casinoMenuItem,
  liveCasinoMenuItem,
  // TODO Временно скрываем в рамках MWL-6896
  // pokerMenuItem,
  tournamentsMenuItem,
];

export const desktopNavMenuWithSport: Array<NavMenuItem> = [
  casinoMenuItem,
  liveCasinoMenuItem,
  // TODO Временно скрываем в рамках MWL-6896
  // pokerMenuItem,
  sportMenuItem,
  sportLiveMenuItem,
  tournamentsMenuItem,
];
