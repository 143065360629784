import { createReducer } from '@reduxjs/toolkit';

import { toggleAviatrixPopup, togglePopupRegistrationBonus, toggleSidebar } from './actions';
import type { UIState } from './types';

const initialState: UIState = {
  showSidebar: false,
  showAviatrixPopup: false,
  isErrorPage: false,
  registrationBonusIsShowed: false,
};

export const uiReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(toggleSidebar, (state, action) => ({
      ...state,
      showSidebar: action.payload,
    }))
    .addCase(togglePopupRegistrationBonus, (state, action) => ({
      ...state,
      registrationBonusIsShowed: action.payload,
    }))
    .addCase(toggleAviatrixPopup, (state, action) => ({
      ...state,
      showAviatrixPopup: action.payload,
    }));
});
