import type { ValidatorGCBParamsByHost } from '@/types';

export const ALLOWED_VALIDATOR_HOSTS = ['banzai.bet'];

export const VALIDATOR_GCB_PARAMS_BY_HOST: ValidatorGCBParamsByHost = {
  'banzai.bet': {
    imageSrc: 'https://seal.cgcb.info/8b089e55-4795-425a-6bf6-fdb75eba6900',
    imageAlt: 'GCB Certificate for banzai.bet',
    href: 'https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJazByWlVFMFJsbFNSRWQwVnpnNVNISkVMMVkwVFZFOVBTSXNJblpoYkhWbElqb2lPR0pNTTNOTkt6ZzVUQ3R3V1hKc04wZ3ZLMVJOVVQwOUlpd2liV0ZqSWpvaU1UbGpaR000WWpjM09EVXpOVEZtT0RoaE4yVTFORGcwTmpjd05XTTROV1ExTldZMlpHUXlZekF5T1RBNU0yVTBPV1l3TjJJd05EVmpZbVkwTWpSbU9DSXNJblJoWnlJNklpSjk=',
  },
};
