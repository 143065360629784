import type { FC } from 'react';
import { getTestProps, useDeposit } from '@mwl/core-lib';

import { BonusPacketCard } from '@/components/BonusPacketCard/BonusPacketCard';
import type { BonusPacketCardClasses } from '@/components/BonusPacketCard/BonusPacketCard.types';
import type { SliderClasses } from '@/components/Slider/Slider';
import { Slider } from '@/components/Slider/Slider';
import { popupsObject } from '@/utils';

import { swiperProps } from './BonusPacketSlider.data';
import type { BonusPacketSliderProps } from './BonusPacketSlider.types';

import styles from './BonusPacketSlider.module.scss';

const sliderClasses: SliderClasses = {
  slide: styles.slide,
  buttonRight: styles.buttonRight,
  buttonLeft: styles.buttonLeft,
};

const cardClasses: BonusPacketCardClasses = {
  container: styles.infoContainer,
  closeButton: styles.closeButton,
  closeIcon: styles.closeIcon,
  scrollbar: styles.infoScrollbar,
  line: styles.infoLine,
};

export const BonusPacketSlider: FC<BonusPacketSliderProps> = ({ className, amount, analytics, ...props }) => {
  const { selectedBonus, changeBonus, bonuses } = useDeposit();

  if (!bonuses.length) {
    return null;
  }

  return (
    <div {...getTestProps(props)} className={cn(styles.root, className)}>
      <Slider {...swiperProps} className={styles.slider} classes={sliderClasses} analytics={analytics}>
        {bonuses.map((bonus) => (
          <BonusPacketCard
            {...popupsObject.deposit.payment.bonusPackets(bonus.id.toString()).nodeProps}
            {...bonus}
            classes={cardClasses}
            size="medium"
            key={bonus.id}
            isActive={selectedBonus?.id === bonus.id}
            onSelect={() => changeBonus(bonus)}
            className={styles.slide}
            withButton={false}
            analytics={analytics}
          />
        ))}
      </Slider>
    </div>
  );
};
