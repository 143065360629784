import { useEffect, useMemo } from 'react';
import type { DepositFormValues, FormState, UseSubmitFormState } from '@mwl/core-lib';
import { extendAnalyticsData, sendAnalyticsData, useDeposit, usePrevious } from '@mwl/core-lib';

import type { DepositFormAnalytics } from '@/types';

import { usePayloadDeposit } from '../../hooks/usePayloadDeposit/usePayloadDeposit';

export const useDepositFormAnalytics = (
  analytics: DepositFormAnalytics | undefined,
  formState: FormState<DepositFormValues>,
  state: UseSubmitFormState,
): DepositFormAnalytics => {
  const { methods } = usePayloadDeposit();
  const { payment, content } = useDeposit();
  const prevForm = usePrevious(content.form);

  const analyticsData = useMemo(() => {
    if (!payment) {
      return {};
    }

    return {
      payment_method: payment.card.footer,
      payment_identity: payment.identity,
      payment_title: payment.title,
      payment_form_type: payment.type,
      min_amount: payment.min,
      amount: formState.values.amount,
    };
  }, [formState.values.amount, payment]);

  const analyticsFullData = useMemo(() => {
    if (!payment) {
      return {};
    }

    return {
      ...analyticsData,
      position: methods.findIndex((method) => method.identity === payment.identity) + 1,
    };
  }, [analyticsData, methods, payment]);

  const fullAnalytics: DepositFormAnalytics = useMemo(
    () => ({
      fields: {
        quickTips: extendAnalyticsData(analytics?.fields?.quickTips, analyticsFullData),
        amount: extendAnalyticsData(analytics?.fields?.amount, analyticsFullData),
        submit: extendAnalyticsData(analytics?.fields?.submit, analyticsFullData),
      },
      bonus: {
        switch: extendAnalyticsData(analytics?.bonus?.switch, analyticsFullData),
        slider: {
          card: extendAnalyticsData(analytics?.bonus?.slider?.card, analyticsFullData),
          info: extendAnalyticsData(analytics?.bonus?.slider?.info, analyticsFullData),
          prev: extendAnalyticsData(analytics?.bonus?.slider?.prev, analyticsData),
          next: extendAnalyticsData(analytics?.bonus?.slider?.next, analyticsData),
        },
      },
      submit: extendAnalyticsData(analytics?.submit, analyticsFullData),
    }),
    [analytics, analyticsFullData, analyticsData],
  );

  useEffect(() => {
    if (state.isSuccess && prevForm === 'create' && content.form !== prevForm) {
      sendAnalyticsData(fullAnalytics?.submit?.success?.eventName, fullAnalytics.submit?.success?.data);
    }

    if (state.error && prevForm === 'create' && content.form !== prevForm) {
      sendAnalyticsData(fullAnalytics.submit?.error?.eventName, fullAnalytics.submit?.error?.data);
    }
  }, [state, content, prevForm, fullAnalytics]);

  useEffect(() => {
    const errors: Array<string> = [];

    Object.keys(formState.errors).forEach((key) => {
      if (formState.errors[key]) {
        errors.push(key);
      }
    });

    if (formState.isTouched && errors.length) {
      sendAnalyticsData(fullAnalytics?.fields?.submit?.error?.eventName, {
        ...(fullAnalytics?.fields?.submit?.error?.data || {}),
        amount: formState.values.amount,
        form_errors: errors,
      });
    }
  }, [fullAnalytics, formState.errors, formState.isTouched, formState.values.amount]);

  return fullAnalytics;
};
