import { createTheme, extendComponent } from '@mwl/ui';

export const theme = createTheme({
  components: {
    FormField: extendComponent('FormField', {
      defaultProps: {
        size: 'md',
      },
    }),
  },
});
